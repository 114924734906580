// extracted by mini-css-extract-plugin
export const main = "vision-module--main--1xtD4";
export const p1 = "vision-module--p1--3r91o";
export const p1Enter = "vision-module--p1Enter--222M5";
export const p1Exit = "vision-module--p1Exit--dM6bW";
export const p2 = "vision-module--p2--2o370";
export const p2Enter = "vision-module--p2Enter--2HPNK";
export const p2Exit = "vision-module--p2Exit--3nr_j";
export const p3 = "vision-module--p3--2Dq4m";
export const p3Enter = "vision-module--p3Enter--1ALzD";
export const p3Exit = "vision-module--p3Exit--1vJT1";
export const p4 = "vision-module--p4--1uSYs";
export const p4Enter = "vision-module--p4Enter--1NQhn";
export const p4Exit = "vision-module--p4Exit--2IiJW";
export const stress = "vision-module--stress--3N0u-";