import React, {createRef, Component} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import * as Styles from '../styles/vision.module.scss';

gsap.registerPlugin(ScrollTrigger);



class Vision extends Component {

    constructor(props){
        super(props);
        this.state = {
            scroll: 0,
            titleRef: createRef(),
            section: createRef(),
            p1: createRef(),
            p2: createRef(),
            p3: createRef(),
            p4: createRef()
        }
        this.scrollObserver = this.scrollObserver.bind(this);
    }


    scrollObserver() {
        const height = window.innerHeight;
        const currentScroll = window.pageYOffset;
        if (window.pageYOffset > this.state.scroll) {
            switch (true){
                case currentScroll > height+1500:
                    this.state.p4.current.classList.add(Styles.p4Exit);
                    this.state.p4.current.classList.remove(Styles.p4Enter);
                    break;
                case currentScroll > height+1150:
                    this.state.p3.current.classList.add(Styles.p3Exit);
                    this.state.p3.current.classList.remove(Styles.p3Enter);
                    this.state.p4.current.classList.add(Styles.p3Exit);
                    this.state.p3.current.classList.remove(Styles.p3Enter);
                    this.state.p4.current.classList.add(Styles.p4Enter);
                    this.state.p4.current.classList.remove(Styles.p4Exit);
                    break;
                case currentScroll > height+800:
                    this.state.p2.current.classList.add(Styles.p2Exit);
                    this.state.p2.current.classList.remove(Styles.p2Enter);
                    this.state.p3.current.classList.add(Styles.p3Enter);
                    this.state.p3.current.classList.remove(Styles.p3Exit);
                    break;
                case currentScroll > height+350:
                    this.state.p1.current.classList.add(Styles.p1Exit);
                    this.state.p1.current.classList.remove(Styles.p1Enter);
                    this.state.p2.current.classList.add(Styles.p2Enter);
                    this.state.p2.current.classList.remove(Styles.p2Exit);
                    break;
                case currentScroll > height - 100:                    
                    this.state.p1.current.classList.add(Styles.p1Enter);
                    this.state.p1.current.classList.remove(Styles.p1Exit);
                    break;
                case currentScroll > height -500:
                    // window.scrollTo(0, height);
                    break;
                default:
                    break;
            }
            this.setState({scroll: currentScroll});
        }
        else {
            switch (true){
                case currentScroll < height:    
                    this.state.p1.current.classList.remove(Styles.p1Enter);
                    this.state.p1.current.classList.add(Styles.p1Exit);
                    break;
                case currentScroll < height+350:
                    this.state.p1.current.classList.remove(Styles.p1Exit);
                    this.state.p1.current.classList.add(Styles.p1Enter);
                    this.state.p2.current.classList.add(Styles.p2Exit);
                    this.state.p2.current.classList.remove(Styles.p2Enter);
                    break;
                case currentScroll < height+800:
                    this.state.p2.current.classList.add(Styles.p2Enter);
                    this.state.p2.current.classList.remove(Styles.p2Exit);
                    this.state.p3.current.classList.remove(Styles.p3Enter);
                    this.state.p3.current.classList.add(Styles.p3Exit);
                    break;
                case currentScroll < height+1150 :
                    this.state.p3.current.classList.add(Styles.p3Enter);
                    this.state.p3.current.classList.remove(Styles.p3Exit);
                    this.state.p4.current.classList.add(Styles.p4Exit);
                    this.state.p4.current.classList.remove(Styles.p4Enter);
                    break;
                case currentScroll < height+1700:
                    this.state.p4.current.classList.add(Styles.p4Enter);
                    this.state.p4.current.classList.remove(Styles.p4Exit);
                    break;
                default:
                    break;
            }
            this.setState({scroll: currentScroll});
        }
    }

    componentDidMount() {
        
        gsap.from(this.state.titleRef.current,{
            scrollTrigger: {
                trigger: this.state.section.current,
                start: "top 70%",
                toggleActions: 'play none none reverse'
            },
            opacity: 0,
            y: 50
        });

        gsap.from(this.state.p1.current,{
            scrollTrigger: {
                trigger: this.state.p1.current,
                start: "top 90%",
                toggleActions: 'play none none reverse'
            },
            opacity: 0,
            y: 50
        });

        gsap.from(this.state.p2.current,{
            scrollTrigger: {
                trigger: this.state.p2.current,
                start: "top 90%",
                toggleActions: 'play none none reverse'
            },
            opacity: 0,
            y: 50
        });

        gsap.from(this.state.p3.current,{
            scrollTrigger: {
                trigger: this.state.p3.current,
                start: "top 90%",
                toggleActions: 'play none none reverse'
            },
            opacity: 0,
            y: 50
        });

        gsap.from(this.state.p4.current,{
            scrollTrigger: {
                trigger: this.state.p4.current,
                start: "top 90%",
                toggleActions: 'play none none reverse'
            },
            opacity: 0,
            y: 50
        });

        
        // window.addEventListener("scroll", () => {this.scrollObserver();});
    }

    render() {

        return (
            <section className={Styles.main} ref={this.state.section} id="vision">
                <h1 ref={this.state.titleRef}>Our Vision</h1>
                
                <div ref={this.state.p1} className={Styles.p1}>
                    <p>
                    Driven for a passion for a healthy life, <span className={Styles.stress}>“Batter House”</span> is a dream come true for millions, spearheaded by four women entrepreneurs, popularly known as <span className={Styles.stress}>“The Force of Four”</span>, who researched lifestyles and food habits of people across India who lived long and lived healthy,  free from ailments and lifestyle prone disease, that plague the modern society.
                    </p>
                </div>
                
                <div ref={this.state.p2} className={Styles.p2}>
                    <p>
                    Every pack from Batter House unravels the secrets for a healthy society, as professed by our ancestors.
                    </p>
                </div>

                <div ref={this.state.p3} className={Styles.p3}>
                    <p>
                    We, <span className={Styles.stress}>“The Force of Four”</span>, at Batter House are poised to make a difference in the lifestyles of Millions of people. Be a part of India’s health story
                    </p>
                    <p>
                    <span>Explore</span>, <span>Emulate</span> and <span>Exemplify</span> life and health in all its beauty. 
                    </p>
                </div>

                <div ref={this.state.p4} className={Styles.p4}>
                    <p>
                    <span>“Batter House for a Better House”</span>
                    </p>
                </div>

            </section>
        );
    }
}

export default Vision;